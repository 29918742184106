import React, { useState } from 'react'

// To use Html5Qrcode (more info below)
import { Html5Qrcode } from 'html5-qrcode';

const App = () => {


  const config = { fps: 10, qrbox: { width: 300, height: 200 } };
  const [value, setValue] = useState('');
  const [scan, setScan] = useState(false);

  const qrCodeSuccessCallback = async (decodedText, _) => {
    setValue(decodedText);
    setScan(false);
  };

  const startScan = () => {
    setValue('');
    setScan(true);

    let html5QrCode = new Html5Qrcode("reader");
    html5QrCode.start({ facingMode: { exact: "environment" } }, config, qrCodeSuccessCallback);
  }

  return (
    <div
      className='box'
    >
      <div className='content'>
        {
          !value && !scan && <button className='btn' onClick={startScan}>Escanear</button>
        }
        <div id="reader" className="reader">
        </div>
        {
          value &&
          <div style={{ textAlign: 'center' }}>
            <h2>Detalle producto</h2>
            <span>Existencia</span>
            <h1>24</h1>
            <span><b>Código:</b> {value}</span><br />
            <span><b>Nombre:</b> {value}</span><br /><br />
            <button className='btn' onClick={startScan}>Volver a escanear</button>
          </div>
        }
      </div>
    </div>
  )
}

export default App